import { MAINNET, TESTNET, SMITH, SWAP, FARM } from "../../../consts/global";

export const NEOX_MAINNET_CONTRACTS = {
  SMITH: "0x5C1d98Cc53ADF648f8900200867050f631c9F8b4",
  NEP: "0x470801faBb8B00b8409b612625eA0DC8b76CD41F",
  SWAP: "0x50d5131bA6e8e08Fb759156ea9cb1e1aC67E3211",
  FARM: "0x356De41B6195210993D00D61F1601A0c9b1a63CF",
  FNEO: "0xa4ff1534E70703C61fE2a9d579784dc2158af913",
  TEST1: "0xc6B03BEa34f6243054455d5aaB41430200F6387c",
  TEST2: "0x8780063989dd430FEEc469AB6b345c9E53E221F1",
  GAS: "",
  WGAS: "0x8e392DB6179B0998d5ED432607a8c7Fe7350DDB2",
};

export const NEOX_CONTRACTS = {
  [MAINNET]: {
    [SMITH]: NEOX_MAINNET_CONTRACTS.SMITH,
    [SWAP]: NEOX_MAINNET_CONTRACTS.SWAP,
    [FARM]: NEOX_MAINNET_CONTRACTS.FARM,
  },
  [TESTNET]: {
    [SMITH]: NEOX_MAINNET_CONTRACTS.SMITH,
    [SWAP]: NEOX_MAINNET_CONTRACTS.SWAP,
    [FARM]: NEOX_MAINNET_CONTRACTS.FARM,
  },
};

export const NEOX_NEP_ADDRESSES = {
  [MAINNET]: NEOX_MAINNET_CONTRACTS.NEP,
  [TESTNET]: NEOX_MAINNET_CONTRACTS.NEP,
};
